<template>
  <div class="joystick-container">
    <div 
      ref="joystick"
      class="joystick"
      :style="style"
      :class="isCircular ? 'is-circular' : 'not-circular'"
      @touchmove="joystickHandleTouch"
      @touchstart="joystickHandleTouch"
    >
      <div class="joystick-center" v-if="isCircular" :style="{ backgroundColor: 'var(--centerColor)' }"></div>

      <div class="joystick-ball"
        @touchmove="handleTouch"
        @mousemove="handleMove"
        @mousedown="handleStart"
        @mouseup="handleRelease"
        @touchend="handleRelease"
        :style="{ backgroundColor: 'var(--userColor)' }">
      </div>
      
      <div class="joystick-stick" v-if="!isCircular"></div>
      
      <div v-if="debug" class="debugger">
        {{this.angle}}°<br>
        {{this.buttons}}
      </div>
    </div>
    <div class="jButtons" v-if="buttons > 1">
      <div v-for="n in buttons"
        :key="`btn-${n}`"
        class="button"
        @mousedown="pressButton(n)"
        @mouseup="releaseButton(n)">
        {{['A','B','C'][n-1]}}
      </div>
    </div>
  </div>
</template>


<script>
// TODO: XY normalizar 
export default {
  props: {
    centerColor: {
      type: String,
      default: '#fafafa'
    },
    isCircular: {
      type: Boolean,
      default: true
    },
    debug: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      angle: 180,
      speed: 0,
      radians: 0,
      size: 240,
      halfSize: 120,
      offset: 0,
      isMouseDown: false,
    };
  },

  computed: {
    //Para pasar variables al css, deben asignarse en el estilo inline 
    style() {
      return {
        "--size": this.isCircular ? '21%' : '40%',
        "--speed": `${this.speed}px`,
        "--offcenter": this.isCircular ? '-208%' : `${this.speed}px`,
        "--angle": this.isCircular ? `calc(${this.angle}deg + 90deg)` : `calc(${this.angle}deg - 90deg)`,
        '--centerColor': this.centerColor
      };
    }
  },

  methods: {
    handleStart() {
      this.isMouseDown = true;
    },
    // joystickHandleStart({ touches: [touch] }) {
    //   if(touch.target.className == "joystick-center") { return}
    //   // console.log("!!!", touch)
    //   const { clientX, clientY } = touch;
    //   const { offsetLeft, offsetTop } = this.$el;
    //   const x = Math.round(clientX - offsetLeft - this.halfSize);
    //   const y = Math.round(clientY - offsetTop - this.halfSize);
    //   this.updatePosition(x, y);
    // },
    joystickHandleTouch({ touches: [touch] }) {
      if(touch.target.className == "joystick-center") { return}
      // console.log(touch)
      const { clientX, clientY } = touch;
      const { offsetLeft, offsetTop } = this.$el;
      const x = Math.round(clientX - offsetLeft - this.halfSize);
      const y = Math.round(clientY - offsetTop - this.halfSize);
      this.updatePosition(x, y);

    },
    handleTouch({ touches: [touch] }) {
      const { clientX, clientY } = touch;
      const { offsetLeft, offsetTop } = this.$el;
      const x = Math.round(clientX - offsetLeft - this.halfSize);
      const y = Math.round(clientY - offsetTop - this.halfSize);
      this.updatePosition(x, y);
    },
    handleMove({ clientX, clientY }) {
      if (!this.isMouseDown) return

      const { offsetLeft, offsetTop } = this.$el;
      const x = Math.round(clientX - offsetLeft - this.halfSize);
      const y = Math.round(clientY - offsetTop - this.halfSize);
      this.updatePosition(x, y);
    },
    handleRelease() {
      if (this.isCircular) return
      this.isMouseDown = false; 

      // Delay para evitar updatear durante el cooldown
      const cooldowntime = this.$parent.EmitCooldownTime
      if(cooldowntime) {
        setTimeout(() => { this.updatePosition(0, 0)}, cooldowntime * 1.2)
      } else {
        this.updatePosition(0, 0);
      }
      
    },
    updatePosition(x, y) {
    
      this.radians = Math.atan2(y, x);
      const angle = Math.round((this.radians * 180) / Math.PI, 4);

      this.angle = angle + (angle > 180 ? -180 : 90);

      if (this.isCircular) {

        this.x = Math.cos(this.radians) * this.offset;
        this.y = Math.sin(this.radians) * this.offset;

      } else {
        this.speed = Math.min(Math.round(Math.sqrt(Math.pow(y, 2) + Math.pow(x, 2))), this.offset);
        this.x = this.speed > this.offset ? Math.cos(this.radians) * this.offset : x;
        this.y = this.speed > this.offset ? Math.sin(this.radians) * this.offset : y;
      }

      this.emitAll();
    },
    pressButton(n){
      console.log('button press:',n)
      this.$emit('releaseButton', {
        button: n,
      });
    },
    releaseButton(n){
      console.log('button release:',n)
      this.$emit('buttonReleased', {
        button: n,
      });
    },
    emitAll(name = "change") {
      this.angle = this.angle - 90; //Angle 0 = radians 0 = 15:00 horas
      this.$emit(name, {
        angle: this.angle,
        radians: this.radians,
        x: this.isCircular ? 0 : this.normalize(this.x, 0, 1),
        y: this.isCircular ? 0 : this.normalize(this.y, 0, 1),
        speed: this.normalize(this.speed, 0, this.halfSize),
      });
    },
    normalize(val, min, max) {
      // console.log(min,max)
      return (val - min) / (max - min);
    },
    resetJoystick(){
      this.size = this.$refs.joystick.offsetWidth;
      this.halfSize = this.size * 0.5;
      this.offset = this.size * 0.375;
      this.speed = 0;
      this.x  = 0;
      if(this.isCircular) {
        this.y = this.offset
      } else {
        this.y = 0;
      }
    }

  },
  mounted() {
    this.resetJoystick();
    window.addEventListener('resize', this.resetJoystick);

    this.emitAll();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetJoystick);
  }
};
</script>


<style lang="scss" scoped>
@import '@/styles/variables.scss';

.joystick-container {
  width: 100%;
  aspect-ratio: 1;
}
.joystick {
  $box-shadow: 0px 3px 16px 0px #0000001F;

  width: 100%;
  height: 100%;
  background-color: #dbdbdb;
  border-radius: 50%;
  position: relative;
  box-shadow: $box-shadow inset;

  // &::before {
  //   $size: 75%;
  //   content: "";
  //   display: block;
  //   width: $size;
  //   height: $size;
  //   position: absolute;
  //   margin: auto;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   // background: $white-bis;
  //   background: var(--centerColor);
  //   border-radius: 50%;
  //   box-shadow: $box-shadow;
  // }
  .joystick-center{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    border-radius: 50%;
    $size: 75%;
    width: $size;
    height: $size;
    box-shadow: $box-shadow;
  }

  .debugger {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: monospace;
  }
}


.joystick-ball {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  box-shadow: 0 0 6px 5px rgba($black, 0.2) inset;
  transform: rotate(var(--angle)) translateY(var(--offcenter));
}
.not-circular {

  &::after {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background: $black-bis;
    position: absolute;
    top: 50%;
    z-index: 8;
    left:  50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .joystick-ball::after {
    content: '';
    width: 40%;
    height: 40%;
    // background: rgba($white, 0.5);
    background: var(--centerColor);
    display: block;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: blur(8px);
  }
}

.joystick-stick {
  display: block;
  width: 16%;
  height: var(--speed);
  position: absolute;
  left: 42%;
  top: 50%;
  background: $black-bis;
  transform: rotate(var(--angle));
  transform-origin: top center;
}

.jButtons {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 3em;

    .button {
      $size: 70px;
      width: $size;
      height: $size;
      border-radius: 50%;
      border: 0;
      background-color: var(--userColor);
      cursor: pointer;
      box-shadow: 0 0 6px 5px rgb(10 10 10 / 20%) inset;
      color: white;
      
    }
}

</style>
